<template>
    <div class="option-wrapper">
        <el-button type="primary" v-hasPermi="['classroomObservationRecords:save']" @click="addObservationRecords">添加观察记录</el-button>
        <div
            style="
            flex: 1;
            display: inline-flex;
            margin-left:10px;
        ">
            <el-button style="margin-left: auto;margin-right: 10px;" @click="exportTableInfo" v-hasPermi="['classroomObservationRecords:export']" :loading="exportLoading">导出</el-button>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Option",
        props: {
            exportLoading: {
                type: Boolean
            }
        },
        methods: {
            addObservationRecords () {
                this.$emit('addObservationRecords')
            },
            exportTableInfo () {
                this.$emit('exportTableInfo')
            },
        }
    }
</script>
<style lang="scss" scoped>
    .option-wrapper {
        box-sizing: border-box;
        // padding-bottom: 10px;
        display: flex;
        flex: 1;
    }
</style>
