<template>
    <div style="margin-right: 10px">
        <keep-alive include="TeacherLeaveRecordView">
            <component
                :is="comList[idx]"
                :record-id="recordId"
                @backWard="backWard"
                @handleTableEdit="handleTableEdit"
            />
        </keep-alive>
    </div>
</template>

<script>

export default {
    name: "TeacherLeaveRecordScrollWrapper",
    components: {
        TeacherLeaveRecordView: () =>
            import(
                /* webpackChunkName:'TeacherLeaveRecordView' */ "@/components/scrollWrapper/TeacherLeaveRecord/index.vue"
            ),
        TeacherLeaveRecordEdit: () =>
            import(
                /* webpackChunkName:'TeacherLeaveRecordEdit' */ "@/components/scrollWrapper/TeacherLeaveRecord/EditPage.vue"
            ),
    },
    data() {
        return {
            idx: 0,
            comList: ["TeacherLeaveRecordView", "TeacherLeaveRecordEdit"],
            type: 'list',
            recordId: ""
        };
    },
    activated() {
        this.setGlobalPageType(this.type)
    },
    methods: {
        /**
         * @Description: 点击返回按钮
         * @DoWhat: 点击返回按钮
         * @UseScenarios: 点击返回按钮
         * @Attention: 点击返回按钮
         * @Author: 武东超
         * @Date: 2023-03-20 14:34:51
         */
        backWard() {
            this.idx = 0;
            this.setGlobalPageType('list')
        },
        /**
         * @Description: 编辑请假记录表单
         * @DoWhat: 编辑请假记录表单
         * @UseScenarios: 编辑请假记录表单
         * @Attention: 编辑请假记录表单
         * @Author: 张冰华
         * @Date: 2023-10-26 17:05:25
         */
        handleTableEdit(data) {
            this.recordId = data
            this.idx = 1;
            this.setGlobalPageType('detail')
        },
        setGlobalPageType(type) {
            this.type = type
            this.$eventDispatch('setGlobalPageType', type)
        },
    },
};
</script>
<style scoped lang="scss">
</style>
